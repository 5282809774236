import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	json,
	useLoaderData,
	useLocation,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import * as Sentry from "@sentry/remix";
import { type ReactNode, useEffect } from "react";
import { $path } from "remix-routes";
import { Header, ignorePath } from "~/components/common/header";
import { Button } from "~/components/ui/button";
import { auth as serverAuth } from "~/services/firebase.server";
import { commitSession, getSession } from "~/services/session.server";
import { getPoints } from "~/shared/getPoint";
import { prisma } from "~/shared/prisma";
import styles from "~/tailwind.css?url";

type DocumentProps = {
	children: ReactNode;
	env?: Record<string, string | undefined>;
};

type ErrorContainerProps = {
	title: string;
	message: string;
	onNavigate: () => void;
};
export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const session = await getSession(request.headers.get("Cookie"));
	const url = new URL(request.url);
	const affiliateId = url.searchParams.get("af");
	const idToken = session.get("idToken");
	const isIgnorePath = ignorePath.includes(url.pathname);
	const user =
		idToken && !isIgnorePath ? await serverAuth.verifyIdToken(idToken) : null;
	const userData = user
		? await prisma.appUser.findUnique({
				where: { firebaseAuthId: user.uid },
			})
		: null;
	const point = userData ? await getPoints(userData.id) : null;

	const ENV = {
		FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
		FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
		FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
		FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
		FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
		FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
		FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,
		SENTRY_DSN: process.env.SENTRY_DSN,
		APP_ENV: process.env.APP_ENV,
	};

	if (affiliateId) {
		session.set("affiliateId", affiliateId);
		url.searchParams.delete("af");

		return json(
			{ ENV, affiliateId, userData, totalPoint: point?.totalPoint },
			{
				headers: {
					"Set-Cookie": await commitSession(session),
				},
			},
		);
	}

	return json({
		ENV,
		userData,
		totalPoint: point?.totalPoint,
	});
};

const ErrorContainer = ({
	title,
	message,
	onNavigate,
}: ErrorContainerProps) => {
	return (
		<div className="error-container m-6 p-6 bg-red-100 border border-red-400 text-red-700 rounded-lg">
			<h1 className="text-m font-bold mb-2">{title}</h1>
			<p className="text-sm mb-4">{message}</p>
			<Button
				className="text-red-700 border border-red-700 bg-white hover:bg-gray-100"
				onClick={onNavigate}
			>
				トップページへ
			</Button>
		</div>
	);
};

export const ErrorBoundary = () => {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);

	const handleNavigate = () => {
		window.location.href = $path("/article/new");
	};

	if (isRouteErrorResponse(error)) {
		return (
			<Document>
				<ErrorContainer
					title={error.data}
					message={`${error.status} : ${error.statusText}`}
					onNavigate={handleNavigate}
				/>
			</Document>
		);
	}

	if (error instanceof Error) {
		return (
			<Document>
				<ErrorContainer
					title="エラーが発生しました"
					message="申し訳ありません、予期せぬエラーが発生しました。"
					onNavigate={handleNavigate}
				/>
			</Document>
		);
	}

	return (
		<Document>
			<ErrorContainer
				title="エラーが発生しました"
				message="原因不明のエラーが発生しました。"
				onNavigate={handleNavigate}
			/>
		</Document>
	);
};

const Document = ({ children, env }: DocumentProps) => {
	return (
		<html lang="ja" className="font-roboto-sans">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>
				{children}
				<ScrollRestoration />
				{env && (
					<script
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{
							__html: `window.ENV = ${JSON.stringify(env)}`,
						}}
					/>
				)}
				<Scripts />
			</body>
		</html>
	);
};

const App = () => {
	const location = useLocation();
	const isTopPage = location.pathname === $path("/");
	const data = useLoaderData<typeof loader>();

	useEffect(() => {
		const unsubscribe = () => {
			const user = data.userData;

			if (user) {
				Sentry.setUser({
					id: user.id,
					email: user.email || undefined,
				});
			} else {
				Sentry.setUser(null);
			}
		};

		return () => unsubscribe();
	}, [data.userData]);

	return (
		<Document env={data.ENV}>
			<div className="flex flex-col min-h-screen">
				{!isTopPage && <Header totalPoint={data.totalPoint} />}
				<div className="flex-grow flex">
					<Outlet />
				</div>
			</div>
		</Document>
	);
};

export default Sentry.withSentry(App);
