import { Link, useLocation, useNavigate } from "@remix-run/react";
import { $path } from "remix-routes";
import { Button } from "~/components/ui/button";

export const ignorePath = [
	$path("/"),
	$path("/account/login"),
	$path("/account/signup"),
	$path("/account/verify"),
	$path("/account/forgot-password"),
	$path("/account/forgot-password/verify"),
];

export const Header = ({ totalPoint }: { totalPoint: number | undefined }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const hasGetPoint = ignorePath.includes(location.pathname);

	return (
		<div className="w-full h-16 shadow-3xl relative z-10 flex justify-between items-center px-12 py-6">
			<Link to={$path("/article/new")}>
				<img
					width={216}
					height={48}
					src="/assets/images/AI-KIJI-MOJI-KUN.png"
					alt="AI-KIJI-MOJI-KUN"
				/>
			</Link>
			{!hasGetPoint ? (
				<div className="my-3 flex items-center">
					{/* TODO: メール一覧画面実装時にpathを更新 */}
					{/* 今回のスコープ外のためコメントアウト */}
					{/* <Link to={$path("/")}>
						<Mail size={24} className="mr-4 cursor-pointer" />
					</Link> */}
					<div className="flex mr-5 px-4 py-1 items-center rounded-3xl justify-between bg-indigo-gray w-[11rem]">
						<img
							src="/assets/images/coin1.png"
							alt="coin"
							className="w-6 h-6"
						/>
						<div className="flex items-baseline">
							<span className="font-bold mr-1 text-right text-sm text-dark-gray">
								{totalPoint?.toLocaleString()}
							</span>
							<p className="text-[10px] text-dark-gray">ポイント</p>
						</div>
					</div>
				</div>
			) : (
				<div className="my-3">
					<Button
						onClick={() => {
							navigate($path("/article/new"));
						}}
						variant="outline"
						className="border-blue-800 w-32 mx-6"
					>
						ログイン
					</Button>
					<Button
						onClick={() => {
							navigate($path("/account/signup"));
						}}
						className="w-32"
					>
						新規登録
					</Button>
				</div>
			)}
		</div>
	);
};
